@import '../../styles/customMediaQueries.css';

.root {
  & p {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
}

.lastUpdated {
  composes: marketplaceBodyFontStyles from global;
  margin-top: 0;
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 54px;
  }
}

.scopedList {
  & p {
    font-family: Georgia, serif;
    line-height: 1.3;
  }

  & li + li {
    margin-top: 11px;
  }

  & ul li {
    font-family: Georgia, serif;
    display: list-item;
    margin-left: 20px;
    text-indent: -7px;
    line-height: 1.2;
  }

  & ul li:before {
    content: "•";
  }

  & code {
    font-size: 14px;
  }
}